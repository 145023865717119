<template>
  <div>
    <b-row>
      <b-col
        cols="3"
        md="3"
      >
        <b-card title="Datos del paciente">
          <b-form-group
            label="Paciente"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Cesar Diaz"
            />
          </b-form-group>
          <b-form-group
            label="Fecha ingreso"
            label-for="date-start"
          >
            <b-form-input
              id="bed"
              v-model="name"
              placeholder="06/08/2021"
            />
          </b-form-group>
          <b-form-group
            label="Cama"
            label-for="bed"
          >
            <b-form-input
              id="bed"
              v-model="name"
              placeholder="HAB_203"
            />
          </b-form-group>
          <b-form-group
            label="Riesgo"
            label-for="risk"
          >
            <b-form-input
              id="risk"
              v-model="name"
              placeholder="Ulcera por presión"
            />
          </b-form-group>
        </b-card>
        <br><br>
        <b-card title="Ordenes asignadas al paciente" />
      </b-col>
      <b-col
        cols="9"
        md="9"
      >
        <b-card title="Listado de medicamentos">

          <b-table
            small
            :fields="fields"
            :items="items"
            responsive="sm"
          >
            <!-- A virtual column -->
            <template #cell(medicine)="data">
              <div class="content-center">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-qr
                  variant="outline-primary"
                  class="btn-icon"
                  size="sm"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button>
                {{ data.value.name }}
              </div>
            </template>

            <!-- A custom formatted column -->
            <template #cell(asigned)="data">
              {{ data.value }}
            </template>

            <!-- A custom formatted column -->
            <template #cell(recived)="data">
              {{ data.value }}
            </template>

            <template #cell(returned)="data">
              <div class="content-center">
                {{ data.value }}
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                >
                  Devolver
                </b-button>
              </div>
            </template>

            <!-- A virtual composite column -->
            <template #cell(programed)="data">
              {{ data.value }}
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell(done)="data">
              {{ data.value.date }} <br> {{ data.value.hour }}
            </template>

            <template #cell(attendee)="data">
              {{ data.value.first + ' ' + data.value.last }}
            </template>

            <template #cell(state)="data">
              <span
                v-if="data.value == 1"
                class="state done"
              />
              <span
                v-if="data.value == 2"
                class="state cancel"
              />
              <span
                v-if="data.value == 3"
                class="state pendient"
              />
            </template>
          </b-table>

          <b-modal
            id="modal-qr"
            centered
            title="Código Qr"
            ok-only
            ok-title="Accept"
            size="lg"
          >
            <b-row>
              <b-col
                cols="6"
                md="6"
              >
                <b-form-group
                  label="Paciente"
                  label-for="medicine"
                >
                  <b-form-input
                    id="medicine"
                    v-model="medicine"
                    placeholder="Ibuprofeno 500 mg"
                  />
                </b-form-group>
                <b-form-group
                  label="Paciente"
                  label-for="cc"
                >
                  <b-form-input
                    id="cc"
                    v-model="cc"
                    placeholder="123456789"
                  />
                </b-form-group>
                <b-form-group
                  label="Paciente"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    placeholder="Cesar Diaz"
                  />
                </b-form-group>
                <b-form-group
                  label="Cama"
                  label-for="bed"
                >
                  <b-form-input
                    id="bed"
                    v-model="name"
                    placeholder="HAB_203"
                  />
                </b-form-group>
                <b-form-group
                  label="Fecha creación"
                  label-for="date-start"
                >
                  <b-form-input
                    id="bed"
                    v-model="name"
                    placeholder="06/08/2021"
                  />
                </b-form-group>
                <b-form-group
                  label="Riesgo"
                  label-for="risk"
                >
                  <b-form-input
                    id="risk"
                    v-model="name"
                    placeholder="Ulcera por presión"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                md="6"
              >
                <div class="container-qr">
                  <img
                    src="https://latam.kaspersky.com/content/es-mx/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png"
                    alt=""
                    class="qr-patient"
                  >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Descargar</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-modal>

        </b-card>
      </b-col>
    </b-row>
    <b-card title="Medicamentos">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Tipo de documento"
            label-for="user-role"
          >
            <v-select
              v-model="typeDocumentSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Documento"
            label-for="document"
          >
            <b-form-input
              id="document"
              v-model="document"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nombres"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="name"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Apellidos"
            label-for="userlastname"
          >
            <b-form-input
              id="userlastname"
              v-model="lastName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Genero"
            label-for="user-role"
          >
            <v-select
              v-model="genderSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="genderOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Fecha de nacimiento"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="birdthdaySelected"
              class="form-control"
              :config="{ dateFormat: 'd-m-Y'}"
              placeholder="DD-MM-YYYY"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        v-if="loading"
        variant="primary"
      >
        Registrando...
      </b-button>
      <b-button
        v-else
        variant="primary"
        @click="validForm"
      >
        Registrar paciente
      </b-button>
    </b-card>

  </div>
</template>

<script>
import {
 BCard, BCardText, BLink, BFormSelect, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BProgress, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

// Services
import { registerPatient } from '@core/services/patientServise'

// Models
import User from '@core/models/user'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
    flatPickr,
    BButton,
    BTable,
  },
  data() {
    return {
      loading: false,
      selected: null,
      typeDocumentSelected: 'CC',
      genderSelected: 'M',
      birdthdaySelected: '',
      document: '',
      name: '',
      lastName: '',
      fields: [
        { key: 'medicine', label: 'Medicamento' },
        { key: 'asigned', label: 'Asignados' },
        { key: 'recived', label: 'Recibidos' },
        { key: 'returned', label: 'Devueltos' },
        { key: 'programed', label: 'Programado' },
        { key: 'done', label: 'Realizado' },
        { key: 'attendee', label: 'Asistente' },
        { key: 'state', label: 'Estrado' },
      ],
      items: [
        {
          medicine: { qr: 'https://latam.kaspersky.com/content/es-mx/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png', name: 'Ibuprofeno 500 mg' },
          asigned: 2,
          recived: 3,
          returned: 1,
          programed: '08:00 PM',
          done: { date: '07/08/2021', hour: '11:00 PM' },
          attendee: { first: 'Fitbit', last: 'Activity Tracker' },
          state: 1,
        },
        {
          medicine: { qr: 'https://latam.kaspersky.com/content/es-mx/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png', name: 'Ibuprofeno 500 mg' },
          asigned: 2,
          recived: 3,
          returned: 1,
          programed: '08:00 PM',
          done: { date: '07/08/2021', hour: '11:00 PM' },
          attendee: { first: 'Fitbit', last: 'Activity Tracker' },
          state: 1,
        },
        {
          medicine: { qr: 'https://latam.kaspersky.com/content/es-mx/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png', name: 'Ibuprofeno 500 mg' },
          asigned: 2,
          recived: 3,
          returned: 1,
          programed: '08:00 PM',
          done: { date: '07/08/2021', hour: '11:00 PM' },
          attendee: { first: 'Fitbit', last: 'Activity Tracker' },
          state: 3,
        },
        {
          medicine: { qr: 'https://latam.kaspersky.com/content/es-mx/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png', name: 'Ibuprofeno 500 mg' },
          asigned: 2,
          recived: 3,
          returned: 1,
          programed: '08:00 PM',
          done: { date: '07/08/2021', hour: '11:00 PM' },
          attendee: { first: 'Fitbit', last: 'Activity Tracker' },
          state: 2,
        },
        {
          medicine: { qr: 'https://latam.kaspersky.com/content/es-mx/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png', name: 'Ibuprofeno 500 mg' },
          asigned: 2,
          recived: 3,
          returned: 1,
          programed: '08:00 PM',
          done: { date: '07/08/2021', hour: '11:00 PM' },
          attendee: { first: 'Fitbit', last: 'Activity Tracker' },
          state: 3,
        },
      ],
    }
  },
  setup() {
    const roleOptions = [
      { label: 'Cédula de ciudadania', value: 'CC' },
      { label: 'Cédula de extrangería', value: 'CE' },
      { label: 'Tarjeta de indentidad', value: 'TI' },
      { label: 'Pasaporte', value: 'PAP' },
    ]
    const genderOptions = [
      { label: 'Masculino', value: 'M' },
      { label: 'Femenino', value: 'F' },
      { label: 'No especificar', value: 'NONE' },
    ]
    return {
      roleOptions,
      genderOptions,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.container-qr {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.qr-patient {
  width: 100%;
  margin: auto;
}
.state {
  width: 1rem;
  height: 1rem;
  display: flex;
  margin: auto;
  border-radius: 50%;
  &.done {
    background-color: #2FCB74;
  }
  &.pendient {
    background-color: #FFAC5D;
  }
  &.cancel {
    background-color: #EC6364;
  }
}
.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
